import React from 'react'

function Unmessage() {
    return (
        <>
            <div className=' w-[78%] h-[95vh]  '>
                <div className=' w-full h-[100%]  flex justify-center border-[#0e1a24] bg-[#121d2c] items-center border-t-[2px] border-l-[1px]'>
                    <h1 className='text-[#a7a7a7]'>Select a people </h1>
                </div>
            </div>
        </>
    )
}

export default Unmessage
