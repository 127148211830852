import React from 'react'

function MentorSessions() {
    return (
        <>
            <div className='w-[100%] h-[100%] justify-center items-center flex '>
                <h1>Mentor Session Not Available</h1>
            </div>

        </>
    )
}

export default MentorSessions
