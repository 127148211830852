import React from 'react'

function RecentlyViewed() {
    return (
        <>
            <div className='w-[100%] h-[100%] justify-center items-center flex '>
                <h1>No Recently Viewed Available</h1>
            </div>

        </>
    )
}

export default RecentlyViewed
