import React from 'react'

function CouponsRewards() {
    return (
        <>
            <div className='w-[100%] h-[100%] justify-center items-center flex '>
                <h1>Coupons Not Available</h1>
            </div>
        </>
    )
}

export default CouponsRewards
