import React from 'react'

function Referrals() {
    return (
        <>
            <div className='w-[100%] h-[100%] justify-center items-center flex '>
                <h1>Referral Not available</h1>
            </div>

        </>
    )
}

export default Referrals
