import React from 'react'

function Watchlist() {
    return (
        <>
            <div className='w-[100%] h-[100%] justify-center items-center flex '>
                <h1>Watchlist Not Available</h1>
            </div>
        </>

    )
}

export default Watchlist
