import React from 'react'
function FirstPriceCertificate() {
    return (
        <>
            <div className='w-[70%] h-[100%] border-[1px] p-[30px] bg-[#fff] border-[#162125]'>
                <div className='border-[#000] w-[100%] h-[100%] border-[1px] p-[10px]'>
                    <div className='border-[#000] w-[100%] h-[100%] border-[1px] p-[10px]'>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FirstPriceCertificate
