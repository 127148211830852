import React from 'react'
import './Slide.css'
function Slide() {
    return (
        <>
            <div className="loaderslide">
                 
            </div>
        </>
    )
}

export default Slide
