import React from 'react'
import MainHeader from '../../MainHeader.tsx'
import MainFooter from '../../MainFooter.tsx'

function Clubfacke() {
    return (
        <>
            <MainHeader />
            <div className='w-[100%] h-[83.3vh] flex justify-center items-center bg-[#0f1627] text-[]'>

                <h1 className=' uppercase text-[50px] font-[700] animate-pulse text-[#ffdd00] max-[480px]:text-[20px] max-[300px]:text-[12px] max-[700px]:text-[30px]'>Coming Soon...</h1>

            </div>
            <MainFooter />

        </>
    )
}

export default Clubfacke
